import trackLink from '@99designs/common/utils/platform/trackLink';

(function ($) {
    $('[data-block-quote-share]').each(function () {
        trackLink(this, 'Clicked Social Share Button', {
            share_via: this.getAttribute('data-block-quote-share-type').toLowerCase(),
            share_subject_type: 'quote in blog post',
            share_message: this.getAttribute('data-block-quote-share-content'),
        });
    });
})(jQuery);
